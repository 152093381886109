'use client'

import { ErrorComponent } from 'monitoring/components/ErrorComponent/ErrorComponent'

export default function GlobalError({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) {
	return (
		<html lang='en'>
			<body>
				<ErrorComponent error={error} reset={reset} />
			</body>
		</html>
	)
}
